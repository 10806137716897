import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseBanner from 'components/banners/base-banner';

const ALT = 'Total Loyalty, get in touch by email or messenger.';

const ContactUsBanner = () => {
  const data = useStaticQuery(graphql`
   query {
     lg: file(relativePath: { eq: "contact/contact_large_desktop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
     md: file(relativePath: { eq: "contact/contact_desktop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
     sm: file(relativePath: { eq: "contact/contact_desktop.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
     xs: file(relativePath: { eq: "contact/contact_mobile.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
   }
 `);

  return (<BaseBanner alt={ALT} data={data}/>);
};

export default ContactUsBanner;
